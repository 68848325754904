<template>
  <div class="wrapper">
    <div class="search-wrap">
      <div class="search-bar">
        <img src="../../images/icon-search.png" />
        <input placeholder="输入搜索内容" v-model="keyword" @input="inputChange"/>
        <span class="icon-guanbi- iconfont close-btn"></span>
      </div>
      <span class="search-btn" @click="toSearch">搜索</span>
    </div>
    <div class="content">
      <div class="swiper-nav">
        <div :class="{'swiper-nav-list': true, 'showborder': currentCate=== item.id}" v-for="item in cateList" :key="item.id" @click="changeCate(item.id)">
          {{item.name}}<span class="border-bom"></span>
        </div>
      </div>
      <div class="cate2-wrap">
        <div class="cate2-wrap-inner">
          <div :class="{'cate2-list': true, 'active': currentTab=== item.id }"  v-for="item in cateSecondList" :key="item.id" @click="changeData(item.id)">
            <img :src="item.picUrl" />
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="swiper-cnt" v-if="groupList.length>0">
          <div class="group-list" @click="goDetail(item)" v-for="item in groupList" :key="item.id">
          <div class="group-img">
            <img :src="item.photoThumbUrl" />
          </div>
          <div class="group-info">
            <h3>{{item.goodsName}}</h3>
            <div class="group-info-list group-info-menber">
              <span class="member-icon">会员专享</span>
              <span class="private_domain" v-if="item.groupType==1">私域拼团</span>
            </div>
            <div class="group-info-list" style="font-size:.12rem">
              <span v-if="item.purchaseType==='0'">{{item.joinNum}}人团 </span>
              <span v-if="item.purchaseType==='0'">共{{item.totalNum}}{{item.unit}}</span>
              <span v-if="item.purchaseType==='0'"> 每人{{item.perNum}}{{item.unit}}</span>
              <span v-if="item.purchaseType==='1'">{{item.totalNum}}{{item.unit}}团</span>
            </div>
            <div class="group-info-list">
              <div class="group-price">
                <span class="price-new">¥{{item.goodsPrice}}/{{item.unit}}</span>
                <span class="price-old">¥{{item.oldPrice}}</span>
              </div>
              <span class="r">已拼{{item.bugCount}}件</span>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <ContentNo v-else :contentTips="contentTips"/>
    </div>
    <!-- <div class="contentno"  v-if="cateList.length === 0">
      <span>暂无拼团，请去后台添加</span>
    </div> -->
    <Tabbar :carNumNew="carNumNew"/>
    <div class="more" ref="more">&nbsp;</div>
  </div>
</template>
<script>
import qs from 'qs'
import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { post, ajaxParam, config } from '../../units/request.js'
import Tabbar from './Tabbar.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  name: 'Home',
  components: {
    Tabbar, ContentNo
  },
  data () {
    return {
      contentTips: '暂无数据'
    }
  },
  setup () {
    const data = reactive({ cat1Id: '', cat2Id: '', cateList: [], groupList: [], cateSecondList: [], currentTab: '', currentCate: '', isLastPage: 0, num: 1, keyword: '', carNumNew: 0 })
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    data.carNumNew = localStorage.carNumNew
    onMounted(() => {
      const winHeight = document.documentElement.clientHeight
      var timer = null
      window.addEventListener('scroll', (res) => {
        // 获取元素距离顶部相对位置
        if (proxy.$refs.more) {
          const scrollHeight = proxy.$refs.more.getBoundingClientRect().top
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            if (winHeight > scrollHeight) {
              // 不能加载多次 防抖
              if (data.isLastPage !== 1) {
                data.num += 1
                getGroupList(data.currentCate, data.currentTab)
              } else {
                data.more = '没有更多数据'
              }
            }
          }, 300)
        }
      })
    })
    const getCateList = async (cat1Id, cat2Id) => {
      const pageNum = 1
      const allLoadCatFlag = 1
      const param = {
        pageNum: pageNum,
        direction: 1,
        shopId: config.shopId,
        loadCatFlag: allLoadCatFlag,
        cat1Id: cat1Id,
        cat2Id: cat2Id
      }
      ajaxParam(param)
      const res = await post('v1/groupPurchase/categoryList.json', qs.stringify(param))
      data.cateList = res.data.categoryList
      if (data.cateList.length !== 0) {
        data.cateSecondList = res.data.categoryList[0].secondList
        data.currentTab = res.data.categoryList[0].secondList[0].id
        data.currentCate = res.data.categoryList[0].id
        getGroupList(res.data.categoryList[0].id, res.data.categoryList[0].secondList[0].id)
      }
    }
    const getGroupList = async (cat1Id, cat2Id) => {
      const allLoadCatFlag = 1
      const param = {
        pageNum: data.num,
        pageSize: 15,
        direction: 1,
        shopId: config.shopId,
        loadCatFlag: allLoadCatFlag,
        cat1Id: cat1Id,
        cat2Id: cat2Id
      }
      ajaxParam(param)
      const res1 = await post('v1/groupPurchase/categoryList.json', qs.stringify(param))
      data.isLastPage = res1.data.isLastPage
      data.groupList.push(...res1.data.list)
    }
    const changeData = (id) => {
      data.num = 1
      getGroupList('', id)
      data.currentTab = id
      data.car2Id = id || ''
      data.isLastPage = 0
      data.groupList = []
    }
    const changeCate = (id) => {
      data.num = 1
      data.isLastPage = 0
      data.groupList = []
      data.currentCate = id
      for (var i = 0; i < data.cateList.length; i++) {
        if (data.cateList[i].id === id) {
          data.cateSecondList = data.cateList[i].secondList
        }
      }
      data.currentTab = data.cateSecondList[0].id
      data.car1Id = id || ''
      getGroupList(id, data.cateSecondList[0].id)
    }
    getCateList('', '')
    const goDetail = (item) => {
      router.push({
        path: '/detail',
        query: {
          id: item.id
        }
      })
    }
    const inputChange = (e) => {
      if (e.data !== undefined) {
        data.keyword = e.data
      }
      toSearch()
    }
    const toSearch = async (e) => {
      const allLoadCatFlag = 1
      const param = {
        pageNum: data.num,
        pageSize: 15,
        direction: 1,
        shopId: config.shopId,
        loadCatFlag: allLoadCatFlag,
        cat1Id: data.car1Id,
        cat2Id: data.car2Id,
        goodsName: data.keyword,
        sortType: 1
      }
      ajaxParam(param)
      const res = await post('v1/groupPurchase/categoryList.json', qs.stringify(param))
      data.groupList = []
      data.groupList.push(...res.data.list)
    }
    const keydown = () => {
    }
    const { carNumNew, cateList, groupList, cateSecondList, currentTab, currentCate } = toRefs(data)
    return { carNumNew, keydown, cateList, groupList, cateSecondList, currentTab, currentCate, changeCate, getCateList, changeData, getGroupList, goDetail, toSearch, inputChange }
  }
}
</script>
<style lang="scss" scoped>
.l {
  float: left;
}
.r {
  float: right;
}
.clear {
  clear: both;
}
a{
  text-decoration: none;
}
.search-wrap{display: flex; justify-content: space-between; align-items: center; margin-bottom:.1rem; background:#fff; padding: 0.1rem; align-items: center; color:#333;}
.search-bar{flex:1; display: flex; align-items: center; height: .32rem; background:#f3f3f3; margin-right: .1rem; padding: 0 .1rem; border-radius: .1rem; line-height: .32rem}
.search-bar img{width:.18rem; height: .18rem; margin-right: .1rem}
.search-bar input{flex:1; border:none; background:none}
.search-bar input:focus{outline:none}
.wrapper {
  background: #f3f3f3;
  position:absolute;
  left: 0;
  right: 0;
  bottom: 0.53rem;
  top:0;
  overflow-y: auto;
}
.swiper-nav {
  height: 0.48rem;
  background: #fff;
  margin-bottom: 0.1rem;
  font-size: 0.15rem;
  display: flex;
  justify-content: space-around;
  line-height: 0.48rem;
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
}
.swiper-nav-list {
  min-width: 1rem;
  text-align: center;
  position: relative;
}
.border-bom {
  width: 0.6rem;
  height: 0.04rem;
  background: #2ac560;
  position: absolute;
  left: 50%;
  margin-left: -0.3rem;
  bottom: 0;
  display: none;
}
.swiper-nav-list a.active {
  color: #2ac560;
  border-bottom: 4px solid #2ac560;
}
.cate2-wrap {
  background: #fff;
}
.cate2-wrap-inner {
  display: flex;
  padding: .16rem .16rem 0;
  flex-wrap: wrap;
}
.cate2-list {
  display: flex;
  flex-direction: column;
  width: 25%;
  // margin-right: 0.15rem;
  // padding: 0.1rem 0.155rem 0;
  align-items: center;
  box-sizing: border-box;
  padding: 0.1rem 0 0;
  margin-bottom:.1rem;
}
.cate2-list img {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  text-align: center;
}
.cate2-list span {
  display: inline-block;
  line-height: 0.48rem;
  text-align: center;
  font-size: 0.15rem;
}
.cate2-wrap-inner .active {
  border: 1px solid #2ac560;
  border-radius: 5px;
}
.group-list {
  margin: .1rem .1rem 0;
  padding: 0.1rem;
  background: #fff;
  border-radius: .08rem;
  overflow: hidden;
  position: relative;
  color:#333
}
.clickarea {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.group-img {
  width: 1rem;
  height: 1rem;
  float: left;
  margin-right: 0.16rem;
}
.group-img img {
  width: 100%;
  height: 100%;
}
.group-info {
  margin: 0 0.1rem 0;
}
.group-info h3 {
  margin-bottom:0;
  margin-top: 0.1rem;
  font-size: 0.15rem;
  font-weight: bold;
}
.group-info-menber{
  display: flex;
}
.member-icon {
  display: inline-block;
  border: 1px solid #ff371e;
  border-radius: 2px;
  margin-right: 0.1rem;
  padding: 0 0.04rem;
  height: .16rem;
  color: #ff371e;
  line-height: .16rem;
  font-size: 0.12rem;
}
.private_domain{
  padding: 0 0.03rem;
  height: .18rem;
  line-height: .18rem;;
  background-color: red;
  color:white;
  font-weight:bold;
  font-size: .12rem
}
.group-info-list {
  margin-top: 0.1rem;
}
.group-price {
  float: left;
}
.price-new {
  color: #ff371e;
}
.price-old {
  text-decoration: line-through;
  font-size: 0.14rem;
}
.showborder .border-bom{
  display: block;
}
.contentno{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:#fff;
  text-align: center;
}
.contentno span {
  position: relative;
  top: 40%;
  font-size: .16rem;
}
</style>
